<template>
  <div class="container" style="background: #F5FFEF" >
    <div class="bottom">
      <img src="../../static/bottomlogo.png" />
    </div>
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="4"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content >
    <div class="produce_content">
      <div class="produce_item">
   
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着教育理念的不断更新，人们越来越意识到孩子的成长离不开学校和家庭，老师和家长缺一不可，只有老师和家长同心协力，密切配合，才能给孩子更好的教育。</p>
      </div>
      </div>
      <div class="produce_item">
   
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由北京实益拓展科技有限责任公司研发出品的“ 中医药文化与劳动技能教育”系统课程，为家庭教育和校园学习搭建了一座桥梁，学校开设“中医药文化”系列课程，通过线上课程、线下实操，让学生不断夯实所学知识点，学生通过学习的知识首先锻炼了自己，然后再传递给家人：父母工作了一天，孩子通过学校学习的头疗法，给爸爸妈妈放松神经、缓解疲劳；家人心率过快或过缓，孩子通过按揉“内关穴”帮助家人调理......通过学校所学内容，更好的健康生活。 </p>
      </div>
      </div>
      <div class="produce_item">
      
      <div class="rightitem">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; “我是小神农”课程让学生认识和了解生活中常见的中草药，并通过学习它的功效让学生们能够在生活中真正运用起来，比如：吃多了，不消化，可以吃一些“山楂”帮助消化；天冷了，受了风寒，可以回家用“花椒”煮水泡泡脚，祛祛寒；书本上的知识不再架空生活，而是融入生活，应用于生活。</p>
      </div>
      </div>

    </div>
    <div class="xmvideo">
                            <video-player  style="width: 100%;height: 100%;object-fit:cover;"  class="video-player vjs-custom-skin"
                                           ref="videoPlayer"
                                           :playsinline="true"
                                           :options="playerOptions"
                            ></video-player>
                        </div>
    </a-layout-content>

    <a-layout-footer style="padding: 0;position: absolute;bottom: 0;width: 100%;">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>

</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "logincontent",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {

        // 视频播放
        playerOptions : {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "https://videotest.bjshiyi.cn/sv/43f953b3-186729b9342/43f953b3-186729b9342.mp4" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth, //播放器宽度
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        }


    }
  },
  methods:{

  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);
  box-sizing: border-box;
}
.produce_content{
display: flex;

  flex-direction: column;
  width: 1403px;
  margin:1rem auto;
  .produce_item{
    display: flex;
   
    flex-direction: row;

   .rightitem{
    
    p{
      color:#444444;
      font-size:21px;
      line-height:40px;
    }
   }
  }
}
.xmvideo{
                margin: 0 auto;
                margin-top:-25px;
                width:480px;
               
            }
.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}
</style>